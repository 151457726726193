import React from "react"
import styled from "styled-components"
import Layout from "../layout"

const MainLayout = styled.div`
  margin: 20px auto;
`

const Heading = styled.h1`
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  padding: 12px 0;
  margin: 0 0 25px;
  font-size: 1.4rem;
  text-transform: uppercase;
`

const PageNotFound = ({ data, children }) => {
  return (
    <Layout>
      <MainLayout className="container container--padded cf">
        <div>
          <Heading>Page Not Found</Heading>
          <div className="cms-content">
            Sorry, the page you were looking for could not be found.
          </div>
        </div>
      </MainLayout>
    </Layout>
  )
}

export default PageNotFound
